<template>
  <customer-page size="full">
    <template v-slot:page-headline>{{ $t(`Übersicht`) }}</template>
    <template v-slot:page-content>
      <section class="section-block" v-if="contractPeriods.length > 0">
        <h2>{{ $t(`Ihre aktuellen Aufträge`) }}:</h2>
        <data-table
            :columns="tableColumns"
            :rows="contractPeriods"
            :is-loading="isLoadingContractPeriods"
        >
          <template v-slot:column-periodState="{ row }">
            <div v-if="!row.contract.active">{{ $t(`deaktiv`) }}</div>
            <div v-else-if="row.contract.cancelled">{{ $t(`gekündigt`) }}</div>
            <div v-else-if="row.periodStatus == 'new'">{{ $t(`Erstmeldung`) }}</div>
            <div v-else-if="row.periodStatus == 'extended'">{{ $t(`Nachmeldung`) }}</div>
            <div v-else-if="row.periodStatus == 'final'">{{ $t(`Jahresabschluss`) }}</div>
            <div v-else>{{ row.periodStatus }}</div>
          </template>
          <template v-slot:column-periodUrl="{ row }">
            <router-link v-if="paymentOpenQuantity && paymentOpenQuantity.quantityId == row.lastContractPeriodQuantityId" :to="{ name: 'payment_start', params: { contractId: paymentOpenQuantity.contractId, contractPeriodQuantityId: paymentOpenQuantity.quantityId }}" href="#" class="btn btn-primary">{{ $t(`Zahlung ausführen`) }}</router-link>
            <router-link v-else :to="{ name: 'contract_view', params: { contractId: row.contractId, contractYear: row.contractYear }}">{{ $t(`anzeigen`) }}</router-link>
          </template>
        </data-table>
      </section>

      <section class="section-block mt-5" v-if="contractDetails">
        <h2>{{ $t(`Ihre Verträge`) }}:</h2>
        <table class="table easy-table contract-details-table">
          <template v-for="contractDetail in contractDetails.contractDetails">
            <thead>
            <tr>
              <td colspan="3"><h4 class="contract-number">{{ $t('Vertrag Nr.') }} {{ contractDetail.contractNumber }}</h4> </td>
            </tr>
            <tr>
              <th>{{ $t('Lizenzjahr') }}</th>
              <th>{{ $t('Status') }}</th>
              <th>{{ $t('Aktionen') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="contractPeriod in contractDetail.contractPeriods">
              <td>{{ contractPeriod.contractYear }}</td>
              <td>
                <span v-if="contractPeriod.cancelled">{{ $t('gekündigt') }}</span>
                <span v-else-if="contractPeriod.isPaymentOpen">{{ $t('Zahlung offen') }}</span>
                <span v-else-if="contractPeriod.isBooked">{{ $t('abgeschlossen') }}</span>
                <span v-else>{{ $t('offen') }}</span>
              </td>
              <td>
                <router-link v-if="contractPeriod.isPaymentOpen" :to="{ name: 'payment_start', params: { contractId: contractDetail.contractId, contractPeriodQuantityId: contractPeriod.paymentOpenQuantityId }}" href="#" class="btn btn-primary">{{ $t(`Zahlung ausführen`) }}</router-link>
                <router-link v-else-if="contractPeriod.isBooked" :to="{ name: 'contract_view', params: { contractId: contractDetail.contractId, contractYear: contractPeriod.contractYear }}">{{ $t(`anzeigen`) }}</router-link>
                <router-link v-else-if="contractPeriod.bookable" :to="{ name: 'contract_new_period', params: { contractId: contractDetail.contractId, contractYear: contractPeriod.contractYear } }" href="#" class="btn btn-primary">&gt; {{ $replacePlaceholders($t('Mengen {contractYear} lizenzieren', { contractYear: contractPeriod.contractYear }), { contractYear: contractPeriod.contractYear }) }}</router-link>
                <span v-else-if="!contractPeriod.bookable">{{ $t('Lizenzierung leider nicht mehr möglich') }}</span>
              </td>
            </tr>
            </tbody>
          </template>
        </table>
      </section>

      <section class="section-block mt-5">
        <div class="section-header d-flex justify-content-between pb-3">
          <h2>{{ $t(`Aktuelle Nachrichten`) }}:</h2>
          <div><router-link class="btn btn-primary" to="/messages">&gt; {{ $t('Alle Nachrichten') }}</router-link></div>
        </div>

        <customer-message-table :limit="5" />
      </section>
      <modal ref="paymentOpenModal" v-if="paymentOpenQuantity" :auto-open="true" :show-close-btn="false" class="payment-open-modal">
        <template v-slot:content>
          <div class="alert alert-warning" role="alert">
            {{ $t('Sie haben einen offenen Auftrag.') }}
          </div>
        </template>
        <template v-slot:footer>
          <router-link @click="$refs.paymentOpenModal.hide();" :to="{ name: 'payment_start', params: { contractId: paymentOpenQuantity.contractId, contractPeriodQuantityId: paymentOpenQuantity.quantityId }}" class="btn btn-primary">{{ $t(`Auftrag jetzt bezahlen`) }}</router-link>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">{{ $t('Schließen') }}</button>
        </template>
      </modal>
      <modal v-if="errorCode" :auto-open="true">
        <template v-slot:content>
          <div class="alert alert-warning" role="alert">
            {{ $t('Leider konnte die Bestellung nicht abgeschlossen werden.') }}
          </div>
        </template>
      </modal>
    </template>
  </customer-page>
</template>

<script>
import CustomerPage from "../customer-page";
import DataTable from "../../component/table/dataTable";
import CustomerMessageTable from "../../component/customer-message/customer-message-table";
import Modal from "../../component/modal/modal";
export default {
  name: "contract-list",
  components: {Modal, CustomerMessageTable, DataTable, CustomerPage},
  props: {
    errorCode: {
      type: [String, null],
      default: null
    }
  },
  data() {
    return {
      isLoadingContractPeriods: false,
      contractYears: [],
      contractDetails: null,
      contractPeriods: [],
      paymentOpenQuantity: null
    }
  },
  created() {
    this.loadContractPeriods();
    this.loadContractDetails();
  },
  computed: {
    tableColumns() {
      return [
        {
          field: 'contract.contractNumber',
          label: this.$t('Vertrag Nr.')
        },
        {
          field: 'contractYear',
          label: this.$t('Jahr')
        },
        {
          field: 'periodState',
          label: this.$t('Status')
        },
        {
          field: 'periodUrl',
          label: this.$t('Aktionen')
        }
      ];
    }
  },
  methods: {
    loadContractPeriods() {
      this.isLoadingContractPeriods = true;
      easyLizeApp.apiClient.getContractPeriodList((contractPeriods) => {
        this.contractPeriods = contractPeriods.elements;
        this.isLoadingContractPeriods = false;
      });
      easyLizeApp.apiClient.getContractYears((contractYears) => {
        this.contractYears = contractYears;
      });
    },
    loadContractDetails() {
      easyLizeApp.apiClient.getContractDetailList((contractDetails) => {
        this.contractDetails = contractDetails;
        contractDetails.contractDetails.forEach((contract) => {
          contract.contractPeriods.forEach((contractPeriod) => {
            if(contractPeriod.isPaymentOpen) {
              this.paymentOpenQuantity = {
                contractId: contract.contractId,
                quantityId: contractPeriod.paymentOpenQuantityId
              };
            }
          });
        });
      });
    }
  }
}
</script>
